<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Bouquet Type</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">CableTv</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Bouquet Type</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                 

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                   
                        <th>code</th>
                        <th>Price</th>
                        <th>Service Charge</th>
                   
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="bouquets.length < 1">No bouquet found</span>
                      <tr v-for="(bouquet,index) in bouquets" :key="index">
                        <td>{{bouquet.name}}</td>
                     
                        <td>{{bouquet.code}}</td>
                         <td>{{bouquet.price | currency }}</td>
                          <td>{{bouquet.service_charge | currency }}</td>
                   
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; displayForm(bouquet)"
                          >&#9998;</button>
                        
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      typeId: this.$route.params.typeId,
      bouquets: [],
      vendors: [],
      editing: false,
      
    };
  },
  components: {

  },
  mounted() {
    this.getbouquets();
    this.getVendors();
  },
  methods: {
    getbouquets() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "cable/bouquet/"+this.typeId+"/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.bouquets = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
   
  
    async displayForm(bouquet) {
      let bouquetName = "";

     
      let bouquetPrice = "";
      let bouquetServiceCharge = "";   
      let code = "";
              

      if(typeof bouquet !== 'undefined') {
        bouquetName = bouquet.name;

        
        bouquetPrice = bouquet.price;
        bouquetServiceCharge = bouquet.service_charge;
        code = bouquet.code;
 
       
       
      }

      const { value: formValues } = await Swal.fire({
        title: (this.editing)? "Edit bouquet" : "Create bouquet",
        html:
          '<div class="form-group">' +
          '<input id="name" type="text" placeholder="Bouquet Name" value="'+ bouquetName+'" class="swal2-input" autocomplete="off">' +
          "</div>"+
        
          
          '<div class="form-group">' +
          '<input id="price" type="text" placeholder="Bouquet Price" value="'+ bouquetPrice+'" class="swal2-input" autocomplete="off">' +
          "</div>"+

          '<div class="form-group">' +
          '<input id="code" type="text" placeholder="Code" value="'+ code+'" class="swal2-input" autocomplete="off">' +
          "</div>"+

            '<div class="form-group">' +
          '<input id="service_charge" type="text" placeholder="Service Charge" value="'+ bouquetServiceCharge+'" class="swal2-input" autocomplete="off">' +
          "</div>"
          ,
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            name: document.getElementById("name").value,
            service_charge: document.getElementById("service_charge").value,
            price: document.getElementById("price").value,
            code: document.getElementById("code").value
         
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        if(this.editing) {

          this.editBouquet(formValues,bouquet.id);
        }
      }
    },

    editBouquet(formValues,id) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "cable/bouquet/"+id+"/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify({
          "cable_tv_type_id": this.typeId,
          ...formValues
        })
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
       
            let bouquetIndex = this.bouquets.findIndex(bouquet => {
              if(bouquet.id == id) return true;
            });
            this.bouquets[bouquetIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
     
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });


    },

    


    
  }
};
</script>

<style scoped>
</style>