<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Physicians</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Physicians</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Physician</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                   <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFilemyFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control" v-model="editPhysician.first_name" />
                  </div>
                  <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control" v-model="editPhysician.last_name" />
                  </div>
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editPhysician.phone_number" />
                  </div>
                 
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  v-if="validated"
                  type="button"
                  @click="updateRider();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Create Physician</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Physician</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                   <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFilemyFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control" v-model="editPhysician.first_name" />
                  </div>
                  <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control" v-model="editPhysician.last_name" />
                  </div>
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editPhysician.phone_number" />
                  </div>
                 
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  v-if="validated"
                  type="button"
                  @click="createRider();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Create Physician</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Physicians</a>
                    <div class="col-1"></div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="physicians.length < 1">No Physician Found</span>
                      <tr v-for="(physician,index) in physicians" :key="index">
                        <td>
                           <img
                            :src="physician.image"
                            class="profile-image"
                            style="width: 50px; height: 50px;"
                            alt="LOGO"
                          />
                        </td>
                        <td>{{physician.first_name | capitalize }} {{ physician.last_name | capitalize }}</td>
                        <td>{{physician.phone_number}}</td>
                        <td>
                           <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(physician)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleterider(physician.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="physicians.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      physicians: [],
      states: [],
      keyword: "",
      editing: false,
      state: "all",
      currentPage: 1,
      totalPages: 1,
      editPhysician: {
        image: null,
        first_name: "",
        last_name: "",
        phone_number: ""
      }
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getriders();
  },
  methods: {
    prepEdit(physician) {
      this.editPhysician = {...physician};
      this.editPhysician.image = null;
    },
     encodeFile(e) {
      let file = e.target.files[0];
      if (!file) return;

      let reader = new FileReader();
      reader.onloadend = () => {
        this.editPhysician.image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    pageChangeHandler(page) {
      this.currentPage = page;
      if (this.keyword.length < 1) this.getriders();
      else this.querySearch();
    },
    getriders() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + "physicians/physicians/all?page=" + this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.physicians = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    updateRider() {
     
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "physicians/physicians/" +
          this.editPhysician.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editPhysician)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let physicianIndex = this.physicians.findIndex(movie => {
              if (movie.id == this.editPhysician.id) return true;
            });
            this.physicians[physicianIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    deleterider(id, index) {
      const link = this.$store.getters.baseURL + "physicians/physicians/" + id + "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete physician?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.physicians.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },

    createRider() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "physicians/physicians/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.editPhysician)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.physicians = [result.data, ...this.physicians];
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  },
  computed: {
    validated() {
      if (this.editPhysician.first_name.length < 1) return false;
      if (this.editPhysician.last_name.length < 1) return false;
      
      if (this.editPhysician.phone_number.length < 11) return false;
   
      return true;
    }
  }
};
</script>

<style scoped></style>
