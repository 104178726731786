<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Admin Purchase</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Data</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Purchase</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
                <div class="card-header">
                    <h4 class="text-center">Airtime Purchase</h4>
                </div>
              <div class="card-body">
                  <div class="form">
                      <div class="row">
                        <div class="col-6">
                            <label class="text-dark">Network</label>

                            <select v-model="vendForm.network_id" @change="getBundles()" class="form-control" :disabled="verified">
                                <option value="0">Select Network</option>
                                <option v-for="(network,index) in airtime" :key="index" :value="network.id">{{network.name}}</option>
                            </select>
                        </div>


                        <div class="col-6">
                            <label class="text-dark">Bundles</label>

                            <select v-model="vendForm.bundle_id" class="form-control" :disabled="verified">
                                <option value="0">Select Bundle</option>
                                <option v-for="(bundle,index) in bundles" :key="index" :value="bundle.id">{{bundle.title}} -- {{bundle.price | currency}}</option>
                            </select>
                        </div>
                      

                       
                    </div>
                    <br />
                  
                    <br />
                     <div class="row" >
                            <div class="col-6">
                          <label class="text-dark">Phone Number</label>
                          <input v-model="vendForm.phone_number" type="text" class="form-control"  />
                        </div>

                        <div class="col-6">
                          <label class="text-dark">Password</label>
                          <input v-model="vendForm.password" type="password" class="form-control" />
                        </div>

                    </div>
                    <br />
                    <div class="row">
                    

                        <div class="col-2"  v-if="validated"> 
                            <button class="form-control btn btn-secondary text-light" @click="verified = false; result.status = false">Refresh</button>
                        </div>

                        <div class="col-5"  v-if="validated">
                            <button class="form-control btn btn-danger text-light" :disabled="loading" @click="vend()">{{ (loading)? 'Please wait...' : "Vend" }}</button>
                        </div>
                    </div>
                  </div>

                 
              
              </div>

            </div>

            <div class="card" v-if="result.status">
                <table class="table">
                    <tr>
                        <th>Network</th>
                        <td>{{result.customer_name}}</td>
                    </tr>
                     <tr>
                        <th>Phone Number</th>
                        <td>{{result.phone_number}}</td>
                    </tr>
                     <tr>
                        <th>Amount</th>
                        <td>{{result.token | currency}}</td>
                    </tr>
                    <!-- <tr>
                        <td><button class="btn btn-primary">INFORM</button></td>
                        <td>
                            <label>Email</label>
                            <input type="text" v-model="inform.email" class="form-control" />

                            <label>Phone Number</label>
                            <input type="text" v-model="inform.phone_number" class="form-control" />
                            
                        </td>
                    </tr> -->
                   

                </table>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>


<script>
import Swal from "sweetalert2";
export default {
    data() {
        return {
            loading: false,
            airtime: [],
            verified: false,
            bundles: [],
            inform: {
                email: "",
                phone_number: ""
            },
         
            vendForm: {
                network_id: 0,
                bundle_id: 0,
                payment_method: "admin",
                password: "",
                phone_number: "",
                payment_reference: "admin"
            },
            result: {
                status: false,
                token: null,
                customer_name: null,
                phone_number: null
            }
        }
    },
    mounted() {
        this.getAirtime();
    },
    methods: {
        getAirtime() {
            fetch(this.$store.getters.baseURL +"data/get_network",{
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => res.json()).then(data => {
                this.airtime = data.data;
            }).catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Check your connection"
                });
            });
        },

        getBundles() {
            fetch(this.$store.getters.baseURL +"data/"+this.vendForm.network_id+"/get_bundles",{
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => res.json()).then(data => {
                this.bundles = data.data;
            }).catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Check your connection"
                });
            });
        },
     

        vend() {
            this.loading = true;
            fetch(this.$store.getters.baseURL +"data/vend",{
                headers: {
                    "Content-Type": "application/json",
                     Authorization: "Bearer " + this.$store.getters.getToken
                },
                body: JSON.stringify(this.vendForm),
                method: "POST"
            }).then(res => res.json()).then(data => {
                
                if(data.status == true) {
                    data = data.data;
                    this.result.status = true;
                    this.result.token = data.order.amount;
                    this.result.customer_name = data.bundle.network.name;
                    this.result.phone_number = data.data.reciever;
                    this.verified = true;
                }else {
                    Swal.fire({
                        icon: "error",
                        title: data.message
                    });
                }
                this.loading = false;
            }).catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Check your connection"
                });
                this.loading = false;
            });
        }
    },
    computed: {
        validated() {
          
            if(this.vendForm.network_id == 0) return false;
            if(this.vendForm.phone_number.length < 11) return false;
            if(this.contract_type == "") return false;

      
            return true;
        }
    }
}
</script>