<template>
  <div class="app-main" id="main">
    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
        <div class="col-md-12 m-b-30">
          <!-- begin page title -->
          <div class="d-block d-sm-flex flex-nowrap align-items-center">
            <div class="page-title mb-2 mb-sm-0">
              <h1>Account Settings</h1>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <nav>
                <ol class="breadcrumb p-0 m-b-0">
                  <li class="breadcrumb-item">
                    <a>
                      <i class="ti ti-home"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">Users</li>
                  <li class="breadcrumb-item active text-primary" aria-current="page">Profile</li>
                </ol>
              </nav>
            </div>
          </div>
          <!-- end page title -->
        </div>
      </div>
      <!-- end row -->

      <!--mail-Compose-contant-start-->
      <div class="row account-contant">
        <div class="col-12">
          <Loader v-if="$store.getters.isLoading" />
          <div v-else class="card card-statistics">
            <div class="card-body p-0">
              <div class="row no-gutters">
                <div class="col-xl-3 pb-xl-0 pb-5 border-right">
                  <div class="page-account-profil pt-5">
                    <div class="profile-img text-center rounded-circle">
                      <div class="pt-5">
                        <div class="bg-img m-auto">
                          <img
                            :src="user.profile_image"
                            class="img-fluid"
                            style="height: 104px;"
                            alt="users-avatar"
                          />
                        </div>
                        <div class="profile pt-4">
                          <h4
                            class="mb-1"
                          >{{user.first_name | capitalize }} {{ user.last_name | capitalize }}</h4>
                          <span
                            class="role"
                            v-for="(role,index) in user.roles"
                            :key="index"
                            @click="removeRole(index)"
                          >{{ role.name | capitalize }},</span>
                        </div>
                      </div>
                    </div>

                    <div class="profile-btn row text-center mb-3">
                      <div class="col-6">
                        <button class="btn btn-secondary" @click="addRole">Add Role</button>
                      </div>
                      <div class="col-6">
                        <input
                          @change="encodeFile()"
                          style="display: none;"
                          type="file"
                          id="myFile"
                          accept="image/x-png, image/gif, image/jpeg"
                          name="filename"
                        />
                        <button
                          @click="uploadFile()"
                          class="btn btn-light text-primary"
                        >Upload Image</button>
                      </div>
                    </div>
                    <div class="text-center">
                      <button @click="deleteUser()" class="btn btn-danger">Delete User</button>
                    </div>

                    <div v-if="(typeof user.stats != 'undefined')" class="py-5 profile-counter">
                      <ul class="nav justify-content-center text-center">
                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.power_transactions | currency }}</h4>
                            <p>Power</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.airtime_transactions | currency }}</h4>
                            <p>Aritime</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.cabletv_transactions | currency }}</h4>
                            <p>Cable TV</p>
                          </div>
                        </li>

                        <li class="nav-item px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.data_transactions | currency }}</h4>
                            <p>Data</p>
                          </div>
                        </li>
                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.bank_transfers | currency }}</h4>
                            <p>Bank Transfers</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.dispatch_requests | currency }}</h4>
                            <p>Dispatch Request</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.event_bookings | currency }}</h4>
                            <p>Event Booking</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.movie_bookings | currency }}</h4>
                            <p>Movie Booking</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.restaurant_orders | currency }}</h4>
                            <p>Restaurant Orders</p>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.service_requests | currency }}</h4>
                            <p>Service Request</p>
                          </div>
                        </li>
                        <li class="nav-item border-right px-3 col-12">
                          <div>
                            <h4 class="mb-0">{{ user.stats.store_orders | currency }}</h4>
                            <p>Store Order</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div v-if="(user.wallet != null)" class="py-5 profile-counter">
                      <ul class="nav justify-content-center text-center">
                        <li class="nav-item border-right px-3 col-6">
                          <div>
                            <h4 class="mb-0">
                              {{ user.wallet.balance }}
                              <span v-if="fundingWallet">
                                <span
                                  style="cursor: pointer; color: red;"
                                  @click="increaseWalletBalance = !increaseWalletBalance"
                                >{{ increaseWalletBalance? '+' : '-'}}</span>
                                {{ walletIncrease }}
                              </span>
                            </h4>
                            <p>Wallet Balance</p>
                            <input
                              v-if="fundingWallet"
                              class="form-control col-8 mb-2 ml-auto mr-auto"
                              @keypress="isNumber($event)"
                              v-model="walletIncrease"
                              type="text"
                            />
                            <button
                              v-if="fundingWallet"
                              style="border-radius: 50px 0;"
                              class="btn btn-secondary mb-2"
                              @click="fundWallet()"
                            >Submit</button>
                            <button
                              style="border-radius: 0 50px;"
                              :class="{btn: true, 'btn-primary': !fundingWallet, 'btn-danger': fundingWallet}"
                              @click="fundingWallet = !fundingWallet"
                            >{{ (fundingWallet)? 'cancel' : 'Fund Wallet' }}</button>
                          </div>
                        </li>

                        <li class="nav-item border-right px-3 col-6">
                          <div>
                            <h4 class="mb-0">
                              {{ user.wallet.credit }}
                              <span v-if="increasingLimit">
                                <span
                                  style="cursor: pointer; color: red;"
                                  @click="increaseWalletCredit = !increaseWalletCredit"
                                >{{ increaseWalletCredit? '+' : '-'}}</span>
                                {{ limitIncrease }}
                              </span>
                            </h4>
                            <p>Credit Limit</p>
                          </div>
                          <input
                            v-if="increasingLimit"
                            class="form-control col-8 mb-2 ml-auto mr-auto"
                            @keypress="isNumber($event)"
                            v-model="limitIncrease"
                            type="text"
                          />
                          <button
                            v-if="increasingLimit"
                            style="border-radius: 0 50px;"
                            class="btn btn-secondary mb-2"
                            @click="increaseLimit()"
                          >Submit</button>
                          <button
                            style="border-radius: 50px 0;"
                            :class="{btn: true, 'btn-primary': !increasingLimit, 'btn-danger': increasingLimit}"
                            @click="increasingLimit = !increasingLimit"
                          >{{ (increasingLimit)? 'cancel' : 'Increase Limit' }}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5 col-md-6 col-12 border-t border-right">
                  <div class="page-account-form">
                    <div class="form-titel border-bottom p-3">
                      <h5 class="mb-0 py-2">Edit Your Personal Settings</h5>
                    </div>
                    <div class="p-4">
                      <form>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label for="name1">Full Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name1"
                              placeholder="First Name"
                              v-model="user.first_name"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="name2">Last Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name2"
                              placeholder="Last Name"
                              v-model="user.last_name"
                            />
                          </div>

                          <div class="form-group col-md-12">
                            <label for="phone1">Phone Number</label>
                            <input
                              type="text"
                              class="form-control"
                              id="phone1"
                              placeholder="09036XXXXXX"
                              v-model="user.phone_number"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="email1">Email</label>
                            <input
                              type="email"
                              class="form-control"
                              id="email1"
                              placeholder="example@example.com"
                              v-model="user.email"
                            />
                          </div>

                           <div class="form-group col-md-12">
                          <label for="email1">Change Password (Min character : 6)</label>
                          <input
                            type="password"
                            class="form-control"
                            placeholder="**********"
                            v-model="user.password"
                          />
                        </div>
                        </div>

                        

                        <div class="form-group">
                          <label for="add1">Status</label>
                          <select v-model="user.active" class="form-control">
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>

                       

                        <button
                          @click.prevent="updateUser"
                          type="submit"
                          class="btn btn-primary"
                        >Update Information</button>
                      </form>
                    </div>
                  </div>
                  <div class="page-account-form">
                    <div class="form-titel border-bottom p-3">
                      <h5 class="mb-0 py-2">BVN Details</h5>
                    </div>
                    <span v-if="user.bvn_details == null">Not Verified</span>
                    <div v-else class="p-4">
                      <table class="table">
                        <tr>
                          <th>BVN</th>
                          <td>{{ user.bvn_details.bvn }}</td>
                        </tr>
                        <tr>
                          <th>Surname</th>
                          <td>{{ user.bvn_details.surname }}</td>
                        </tr>
                        <tr>
                          <th>First Name</th>
                          <td>{{ user.bvn_details.first_name }}</td>
                        </tr>
                        <tr>
                          <th>Middle Name</th>
                          <td>{{ user.bvn_details.middle_name }}</td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>{{ user.bvn_details.mobile_number }}</td>
                        </tr>
                        <tr>
                          <th>Date of Birth</th>
                          <td>{{ user.bvn_details.dob }}</td>
                        </tr>
                        <tr>
                          <th>Verified At</th>
                          <td>{{ user.bvn_details.created_at }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 border-t col-12">
                  <div class="page-account-form">
                    <div class="form-titel border-bottom p-3">
                      <h5 class="mb-0 py-2">Histories</h5>
                    </div>
                    <div v-if="(typeof user.stats != 'undefined')" class="p-4">
                      <ul>
                        <li>
                          <router-link :to="'/airtime_transactions/'+id">View Airtime Histories</router-link>
                        </li>
                        <li>
                          <router-link :to="'cable_transactions'+id">View Cable-TV Histories</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="page-account-form">
                    <div class="form-titel border-bottom p-3">
                      <h5 class="mb-0 py-2">Account Details</h5>
                    </div>
                    <span v-if="user.account == null">No Account Generated</span>
                    <div v-else class="p-4">
                      <table class="table">
                        <tr>
                          <th>Account Name</th>
                          <td>{{ user.account.account_name }}</td>
                        </tr>
                        <tr>
                          <th>Bank Name</th>
                          <td>{{ user.account.bank_name }}</td>
                        </tr>
                        <tr>
                          <th>Account Reference</th>
                          <td>{{ user.account.account_reference }}</td>
                        </tr>
                        <tr>
                          <th>Collection Channel</th>
                          <td>{{ user.account.collection_channel }}</td>
                        </tr>
                        <tr>
                          <th>Currency Code</th>
                          <td>{{ user.account.currency_code }}</td>
                        </tr>
                        <tr>
                          <th>Customer Email</th>
                          <td>{{ user.account.customer_email }}</td>
                        </tr>
                        <tr>
                          <th>Reservation Reference</th>
                          <td>{{ user.account.reservation_reference }}</td>
                        </tr>
                        <tr>
                          <th>Account Type</th>
                          <td>{{ user.account.reserved_account_type }}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>{{ user.account.status }}</td>
                        </tr>
                        <tr>
                          <th>Created At</th>
                          <td>{{ user.account.created_at }}</td>
                        </tr>
                        <tr>
                          <th>Created On</th>
                          <td>{{ user.account.created_on }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--mail-Compose-contant-end-->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      user: {},
      walletIncrease: 0,
      fundingWallet: false,
      increasingLimit: false,
      limitIncrease: 0,
      roles: [],
      increaseWalletBalance: true,
      increaseWalletCredit: true
    };
  },
  mounted() {
    this.getUser();
    this.getRoles();
  },
  methods: {
    getUser() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + "users_management/" + this.id + "/single",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.user = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    getRoles() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "role_management/all_roles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.roles = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    addRole() {
      let roles = this.roles.filter(n => {
        let index = this.user.roles.findIndex(item => {
          if (item.name == n.name) return true;
        });
        if (index < 0) return true;
      });
      roles = roles.map(({ name }) => name);

      if (roles.length < 1) return;

      Swal.fire({
        title: "Select Role",
        input: "select",
        inputOptions: roles,
        inputPlaceholder: "Select Roles",
        showCancelButton: true,
        inputValidator: value => {
          if (!value) return;
          let roleIndex = this.roles.findIndex(item => {
            if (item.name == roles[value]) return true;
          });

          this.user.roles.push(this.roles[roleIndex]);
        }
      });
    },
    removeRole(index) {
      this.user.roles.splice(index, 1);
    },
    deleteUser() {
      const link =
        this.$store.getters.baseURL + "users_management/" + this.id + "/delete";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete User?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.$router.go(-1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },
    updateUser() {
      this.$store.commit("setLoading", true);

      let updateUser = { ...this.user };
      if (
        updateUser.profile_image != null &&
        updateUser.profile_image.length < 100
      )
        delete updateUser.profile_image;
      fetch(
        this.$store.getters.baseURL + "users_management/" + this.id + "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(updateUser)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    uploadFile() {
      document.getElementById("myFile").click();
    },
    encodeFile() {
      let file = document.getElementById("myFile").files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.user.profile_image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    increaseLimit() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "wallet/admin/" +
          this.id +
          "/modify_credit",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify({
            amount: this.limitIncrease,
            increase: this.increaseWalletCredit
          })
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.user.wallet = result.data;
            this.increasingLimit = false;
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    fundWallet() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "wallet/admin/" +
          this.id +
          "/modify_wallet",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify({
            amount: this.walletIncrease,
            increase: this.increaseWalletBalance
          })
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.user.wallet = result.data;
            this.fundingWallet = false;
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>
.role:hover {
  color: red;
  cursor: pointer;
}
</style>