<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Sub Task</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Task</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">
                      Tasks
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="row export-buttons m-b-20">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        placeholder="search"
                        class="form-control"
                      />
                    </div>

                    <div class="col-3">
                      <select
                        v-model="status"
                        class="form-control"
                      >
                        <option v-for="status in allStatus" :key="status" :value="status ">{{status}}</option>

                      
                      </select>
                    </div>

                   

                    <div class="col-3">
                      
                      <div>
                        <button class="btn btn-success" @click="getTransactions()">Filter</button>
                      </div>

                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
               
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                      
                        <th></th>
                        <th>Task Id</th>
                        <th>Settlement Status</th>
                        <th>User</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Settlement Id</th>
                        <th>Completion Date</th>
                        <th>Reject Reason</th>
                        <th>Execution time in seconds </th>
                        <th>Admin Note</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                     <span v-if="subTasks.length < 1">No Task</span>
                    <tbody v-else>
                      <tr v-for="(subtask,index) in subTasks" :key="index">
               
                          <td>{{ subtask.id }}</td>
                          <td>{{ subtask.task_id }}</td>
                          <td>{{ subtask.settlement_status }}</td>
                          <td>{{ subtask.executor_user_id }}</td>
                          <td>{{ subtask.amount | currency }}</td>
                          <td>{{ subtask.task_status }}</td>
                          <td>{{ subtask.settlement_id }}</td>
                          <td>{{ subtask.completion_date }}</td>
                          <td>{{ subtask.reject_reason }}</td>
                          <td>{{ subtask.execution_time_in_seconds }}</td>
                          <td>{{ subtask.admin_note }}</td>
                          <td>{{ subtask.created_at }}</td>
                          <td>{{ subtask.deleted_at }}</td>
                          
                          <td>
                            <div v-if="subtask.task_status != 'IN_APPEAL'">
                              <textarea v-model="subtask.reason" class="form-control"></textarea>
                               <button @click="updateStatus(subtask.id, 'COMPLETED', subtask.reason)" class="btn btn-success mr-2">COMPLETE</button>
                                
                               <button @click="updateStatus(subtask.id, 'FAILED', subtask.reason)" class="btn btn-danger">FAIL</button>

                            </div>

                          </td>
                      </tr>
                    </tbody>
                  </table>
                   <sliding-pagination
                   v-if="subTasks.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
// import { RouterLink } from "vue-router";

export default {
  data() {
    return {
      userId: this.$route.params.userId,
      taskId: this.$route.params.taskId,
      subTasks: [],
      interval: null,
      keyword: "",
      currentPage: 1,
      totalPages: 1,
      status: "ALL",
      approvalStatus: "ALL",
      reason: "",
      allStatus: ["ALL","COMPLETED","FAILED","IN_PROGRESS","REJECTED","SUBMITTED","IN_APPEAL"]
    };
  },
  components: {
   
},

  mounted() {
 
    this.getTransactions();
  },
  methods: {
   
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.getTransactions();
    },
   
   
    getTransactions() {
      
      this.$store.commit("setLoading", true);
      let link = this.$store.getters.baseURL + "task/admin/getSubTask?&page=" +this.currentPage + 
     "&status="+this.status +
      ((this.taskId != undefined )? "&task_id="+this.taskId : "") +
      ((this.userId != undefined)? "&user_id="+this.userId : "")
      
      ;

      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {

            this.subTasks = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    updateStatus(id, status, note) {
      
      this.$store.commit("setLoading", true);
      let link = this.$store.getters.baseURL + "task/admin/resolveDispute";

      let data = {
        sub_task_id: id,
        status: status,
        admin_note: note
      };

      fetch(link, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.getTransactions();
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

  
  }
};
</script>

<style scoped>
</style>