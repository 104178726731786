<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>System vouchers</h1>
              </div>

              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Tables</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">System vouchers</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <div class="col-1"></div>

                    <select v-model="role" @change="searchvoucherByRole()" class="form-control col-2">
                      <option
                        v-for="role in roles"
                        :key="role.name"
                        :value="role.name"
                      >{{ role.name | capitalize }}</option>
                    </select>

                    <div class="col-3">
                      <input
                        class="form-control"
                        type="text"
                        v-model="keyword"
                        @keyup="searchvouchers()"
                        placeholder="search"
                      />
                      
                    </div>

                    <div
                       
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm "
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>

                     

                    <button @click="displayForm()" class="btn btn-secondary col-2">Create New</button>
                    <div class="col-1"></div>
                    <button @click="displayMassForm()" class="btn btn-primary col-2">Create Many</button>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        
                        <th>Code</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Usage</th>
                        <th>Used</th>
                        <th>usage left</th>
                        <th>Created</th>
                        <th>Expires</th>
                      
                        <th colspan="3">Actions</th>
                      </tr>
                    </thead>
                    <span v-if="vouchers.length < 1">No voucher Available</span>
                    <tbody v-else>
                      <tr v-for="(voucher,index) in vouchers" :key="index">
                      
                        <td>{{voucher.voucher_code}}</td>
                        <td>{{voucher.amount | currency }}</td>
                        
                        <td>
                            {{ (voucher.active)? "Active" : "Inactive" }}
                        </td>
                        <td>{{ voucher.usage }}</td>
                        <td>{{ voucher.usages.length }}</td>
                         <td>{{ voucher.usage - voucher.usages.length }}</td>
                        <td>{{voucher.created_at | formatDate}}</td>
                        <td>{{voucher.expires_at | formatDate}}</td>
                      

                        <td v-if="iLoading(voucher.id)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                        <td v-else>
                          <button
                            v-if="voucher.active"
                            @click="toggleActiveStatus(voucher.id)"
                            class="btn btn-danger"
                          >Deactivate</button>
                          
                          <button
                            v-else
                            @click="toggleActiveStatus(voucher.id)"
                            class="btn btn-primary"
                          >Activate</button>
                        </td>
                        <td>
                          <button @click="viewUsages(voucher.usages)" class="btn btn-secondary">usage</button>
                         
                        </td>
                         <td>
                          <button  @click="deleteVoucher(index)" class="btn btn-danger">🗑</button>
                       
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <sliding-pagination
                    v-if="vouchers.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

// import Paginate from 'vuejs-paginate'

export default {
  components: {
    
 
  },
  data() {
    return {
        roles: [
            {"name" :"all"},
            {"name" :"active"},
            {"name" :"inactive"},
            {"name" :"expired"}
        ],
      currentPage: 1,
      vouchers: [],
      keyword: "",  
      role: "all",
      bin: [],
      loadingBtn: [],
      totalPages: 1,
      interval: null
    };
  },
  computed: {},
  mounted() {

    this.searchvoucherByRole();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.searchvoucherByRole();
    },
    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    },

   
    searchvouchers() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.searchvoucherByRole();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "voucher/" +
          this.keyword +
          "/search",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.vouchers = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    searchvoucherByRole() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "voucher/" +
          this.role +
          "/all?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.vouchers = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    toggleActiveStatus(id) {
      this.loadingBtn.push(id);
      fetch(
        this.$store.getters.baseURL + "voucher/" + id + "/togge_active",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            console.log(this.vouchers);
            let voucherID = this.vouchers.findIndex((voucher) => {
              
              if (voucher.id == result.data.id) return true;
            });

            this.vouchers[voucherID] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    async displayForm() {
      
      const { value: formValues } = await Swal.fire({
        title: "Create voucher",
        html:
          '<div class="form-group">' +
          '<input id="voucher_code" type="text" placeholder="Voucher Code" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="amount" type="text" placeholder="Amount" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="usage" type="text" placeholder="Usage" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="expires_at" type="date" class="swal2-input" placeholder="Phone Number" autocomplete="off">' +
          "</div>" ,
         
        
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            voucher_code: document.getElementById("voucher_code").value,
            amount: document.getElementById("amount").value,
            usage: document.getElementById("usage").value,
            expires_at: document.getElementById("expires_at").value,
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        this.createVoucher(formValues);
      }
    },

    async displayMassForm() {
      
      const { value: formValues } = await Swal.fire({
        title: "Create voucher",
        html:
          '<div class="form-group">' +
          '<input id="number" type="number" min="1" max="20" placeholder="Number" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="amount" type="text" placeholder="Amount" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="usage" type="text" placeholder="Usage" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="expires_at" type="date" class="swal2-input" placeholder="Phone Number" autocomplete="off">' +
          "</div>" ,
         
        
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            number: document.getElementById("number").value,
            amount: document.getElementById("amount").value,
            usage: document.getElementById("usage").value,
            expires_at: document.getElementById("expires_at").value,
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        this.createMassVoucher(formValues);
      }
    },

    createMassVoucher(data) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "voucher/create_mass", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
         
          if (result.status) {
              
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    createVoucher(data) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "voucher/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
         
          if (result.status) {
               let data = result.data;

            this.vouchers = [data,...this.vouchers];
            this.$forceUpdate();
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    viewUsages(usages) {
        console.log(usages);
        let headers = `<tr>
                        <th>Date</th>
                        <th>Amount Used</th>
                        <th>Amount Left</th>
                    </tr>`;
        let tableContent = usages.map(usage => {
            return `<tr>
                        <td>`+usage.created_at+`</td>
                        <td>`+ new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'NGN' }).format(usage.amount_used) +`</td>
                        <td>`+  new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'NGN' }).format(usage.amount_left)+`</td>    
                    </tr>`
        });
       

        Swal.fire({
            title: "Voucher Usage",
            html: `<table class="table" ><thead>`+headers+`</thead><tbody>`+tableContent+`</tbody></table>`,
            width: '800px'
        });
    },

    deleteVoucher(index) {
     
      const link =
        this.$store.getters.baseURL + "voucher/" + this.vouchers[index].id + "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete Voucher?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                    this.vouchers.splice(index,1);
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    
    }
  }
};
</script>

<style scoped>

</style>