<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Banks</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Banks</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Bank</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editBank.name" />
                  </div>
                  <div class="form-group">
                    <label>Code</label>
                    <input type="email" class="form-control" v-model="editBank.code" />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="updateBank();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <div class="col-1"></div>
                    <select
                      class="form-control col-2"
                      v-model="status"
                      @change.prevent="getBanks()"
                    >
                      <option value="all">All</option>
                      <option value="1">Active</option>
                      <option value="0">InActive</option>
                    </select>

                    <div class="col-2"></div>

                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchBanks()"
                        placeholder="search"
                        class="form-control"
                      />
                    </div>
                    <div
                      v-if="interval != null"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Status</th>
                        <th colspan="2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="banks.length < 1">No Bank Found</span>
                      <tr v-for="(bank,index) in banks" :key="index">
                        <td>{{bank.name}}</td>
                        <td>{{bank.code}}</td>
                        <td>{{bank.active? 'active' : 'inactive'}}</td>

                        <td v-if="iLoading(bank.id)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                        <td v-else>
                          <button
                            class="btn btn-danger"
                            @click.prevent="toggleStatus(bank.id,index)"
                          >
                            <template v-if="!bank.active">Activate</template>
                            <template v-else>Deactivate</template>
                          </button>
                        </td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(bank)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="banks.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      banks: [],
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editBank: {},
      status: "all",
      loadingBtn: [],
      totalPages: 1,
      interval: null,
      keyword: ""
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getBanks();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;

      this.getBanks();
    },
    prepEdit(bank) {
      this.editBank = { ...bank };
      this.editBank.image = null;
    },
    getBanks() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "bank/list/" +
          this.status +
          "?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.banks = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    toggleStatus(id, index) {
      this.loadingBtn.push(id);
      const link =
        this.$store.getters.baseURL + "bank/list/" + id + "/toggle_active";

      fetch(link, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.banks[index] = result.data;
            this.$forceUpdate();
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        });
    },

    updateBank() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "bank/list/" +
          this.editBank.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editBank)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let bankIndex = this.banks.findIndex(bank => {
              if (bank.id == this.editBank.id) return true;
            });
            this.banks[bankIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    searchBanks() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },

    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getBanks();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "bank/list/" +
          encodeURI(this.keyword) +
          "/search",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.banks = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },

    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    }
  }
};
</script>

<style scoped></style>
