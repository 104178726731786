<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Admin Purchase</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Power</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Purchase</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-header">
                <h4 class="text-center">Power</h4>
              </div>
              <div class="card-body">
                <div class="form">
                  <div class="row">
                    <div class="col-6">
                      <label class="text-dark">Disco</label>

                      <select
                        v-model="verifyForm.disco_id"
                        class="form-control"
                        :disabled="verified"
                      >
                        <option value="0">Select Disco</option>
                        <option
                          v-for="(disco,index) in discos"
                          :key="index"
                          :value="disco.id"
                        >{{disco.name}}</option>
                      </select>
                    </div>

                    <div class="col-6">
                      <label class="text-dark">Contract Type</label>
                      <select
                        v-model="verifyForm.contract_type"
                        class="form-control"
                        :disabled="verified"
                      >
                        <option value>Select Type</option>
                        <option value="prepaid">Prepaid</option>
                        <option value="postpaid">Postpaid</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-6">
                      <label class="text-dark">Meter Number</label>
                      <input
                        v-model="verifyForm.meter_no"
                        type="text"
                        class="form-control"
                        :disabled="verified"
                      />
                    </div>

                    <div class="col-6" v-if="verified">
                      <label class="text-dark">Customer Name</label>
                      <input
                        v-model="vendForm.customer_name"
                        type="text"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <br />
                  <div class="row" v-if="verified">
                    <div class="col-6">
                      <label class="text-dark">Amount</label>
                      <input v-model="vendForm.amount" type="text" class="form-control" />
                    </div>

                    <div class="col-6">
                      <label class="text-dark">Password</label>
                      <input v-model="vendForm.password" type="password" class="form-control" />
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-5" v-if="validated && !verified">
                      <button
                        class="form-control btn btn-primary text-light"
                        :disabled="loading"
                        @click="verifyMeter()"
                      >{{ (loading)? 'Please wait...' : "Verify" }}</button>
                    </div>

                    <div class="col-2" v-if="verified">
                      <button
                        class="form-control btn btn-secondary text-light"
                        @click="verified = false; result.status = false"
                      >Refresh</button>
                    </div>

                    <div class="col-5" v-if="verified">
                      <button
                        class="form-control btn btn-danger text-light"
                        :disabled="loading"
                        @click="vend()"
                      >{{ (loading)? 'Please wait...' : "Vend" }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-if="result.status">
              <table class="table">
                <tr>
                  <th>Customer Name</th>
                  <td>{{result.customer_name}}</td>
                </tr>
                <tr>
                  <th>Meter N0</th>
                  <td>{{result.meter_no}}</td>
                </tr>
                <tr>
                  <th colspan="2" class="text-primary text-center">
                    <h2 class="text-primary">{{result.token}}</h2>
                  </th>
                </tr>

                <tr>
                  <td>
                    <button @click="sendMessage()" v-if="!sent" class="btn btn-primary">INFORM</button>
                  </td>
                  <td>
                    <label>Email</label>
                    <input type="text" v-model="inform.email" class="form-control" />

                    <label>Phone Number</label>
                    <input type="text" v-model="inform.phone_number" class="form-control" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>


<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      discos: [],
      verified: false,

      inform: {
        email: "",
        phone_number: "",
        transaction_id: null
      },
      verifyForm: {
        disco_id: 0,
        meter_no: "",
        contract_type: ""
      },
      vendForm: {
        reference_id: null,
        amount: 0,
        payment_method: "admin",
        password: "",
        customer_name: "",
        payment_reference: "admin"
      },
      result: {
        status: false,
        token: null,
        customer_name: null,
        meter_no: null
      },
      sent: false
    };
  },
  mounted() {
    this.getDiscos();
  },
  methods: {
    getDiscos() {
      fetch(this.$store.getters.baseURL + "power/get_discos", {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .then(data => {
          this.discos = data.data;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    verifyMeter() {
      this.loading = true;
      fetch(this.$store.getters.baseURL + "power/verify_meter", {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.verifyForm),
        method: "POST"
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == true) {
            this.vendForm.reference_id = data.data.reference_id;
            this.vendForm.customer_name = data.data.customer_name;
            this.verified = true;
          } else {
            Swal.fire({
              icon: "error",
              title: data.message
            });
          }
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loading = false;
        });
    },

    vend() {
      this.loading = true;
      fetch(this.$store.getters.baseURL + "power/vend", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.vendForm),
        method: "POST"
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == true) {
            data = data.data;
            this.result.status = true;
            this.result.token = data.result.token;
            this.result.customer_name = data.transaction.customer_name;
            this.result.meter_no = data.transaction.meter_no;
            this.inform.transaction_id = data.ptransaction.id;
            this.verified = true;
          } else {
            Swal.fire({
              icon: "error",
              title: data.message
            });
          }
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loading = false;
        });
    },

    sendMessage() {
      this.loading = true;
      this.sent = true;
      fetch(this.$store.getters.baseURL + "power/inform", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.inform),
        method: "POST"
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == true) {
            Swal.fire({
              title: "Message Sent",
              icon: "success"
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.message
            });
            this.sent = false;
          }
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loading = false;
          this.sent = false;
        });
    }
  },
  computed: {
    validated() {
      if (this.verifyForm.disco_id == 0) return false;
      if (this.verifyForm.meter_no.length < 10) return false;
      if (this.contract_type == "") return false;

      return true;
    }
  }
};
</script>