<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Power Transactions</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Power</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Transactions</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchTransactions()"
                        placeholder="search"
                      />
                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Reference</th>
                        <th>Order No</th>
                        <th>Reciept No</th>
                        <th>Customer Name</th>
                        <th>Customer Meter Name</th>
                        <th>Customer Meter Number</th>
                        <th>Disco Name</th>
                        <th>Contract Type</th>
                        <th>Service Provider</th>
                        <th>Access Token</th>
                        <th>Payment Ref</th>
                        <th>Debt Amount</th>
                        <th>Outstanding Debt</th>
                        <th>Token</th>
                        <th>Units</th>
                        <th>Free Units</th>
                        <th>Service Charge</th>
                        <th>Token Amount</th>
                        <th>Amount</th>
                        <th>Payment Status</th>
                        <th>Code</th>
                        <th>Vend Ref</th>
                        <th>Vendor</th>
                        <th>Comment</th>
                        <th>Vend Time</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <span v-if="transactions.length < 1">No transaction available</span>
                    <tbody v-else>
                      <tr v-for="(transaction,index) in transactions" :key="index">
                        <td>{{ transaction.reference_id }}</td>
                        <td>{{ transaction.order_no }}</td>
                        <td>{{ transaction.receipt_no }}</td>
                        <td>
                          <router-link v-if="transaction.user"
                            :to="'/profile/'+transaction.user.id"
                          >{{ transaction.user.first_name | capitalize }} {{ transaction.user.last_name | capitalize }}</router-link>
                        </td>
                        <td>{{ transaction.meter_customer_name | capitalize }}</td>
                        <td>{{ transaction.meter_number }}</td>
                        <td>{{ transaction.meter_number }}</td>
                        <td>{{ transaction.disco.name | capitalize }}</td>
                        <td>{{ transaction.contract_type | capitalize }}</td>
                        <td>{{ transaction.disco.service_provider | capitalize }}</td>
                        <td>{{ transaction.payment_reference }}</td>
                        <td>{{ transaction.debt_amount | currency }}</td>
                        <td>{{ transaction.debt_remaining | currency }}</td>
                        <td>{{ transaction.token }}</td>
                        <td>{{ transaction.units }}</td>
                        <td>{{ transaction.free_units }}</td>
                        <td>{{ transaction.service_charge }}</td>
                        <td>{{ transaction.token_amount | currency }}</td>
                        <td>{{ transaction.total_amount | currency }}</td>
                        <td>{{(transaction.paid)? "Paid" : "Unpaid"}}</td>

                        <td>{{ transaction.code }}</td>
                        <td>{{ transaction.vend_ref }}</td>
                        <td>{{ transaction.vendor }}</td>
                        <td>{{ transaction.comment }}</td>
                        <td>{{ transaction.vend_time | formatDate }}</td>
                        <td>{{ transaction.created_at | formatDate }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="transactions.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      userId: this.$route.params.id,
      transactions: [],
      interval: null,
      keyword: "",
      currentPage: 1,
      totalPages: 1,
      searching: false,
    };
  },
  components: {},
  mounted() {
    this.getTransactions();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      if(!this.searching)
        this.getTransactions();
      else 
        this.querySearch();
    },
    getTransactions() {
      this.$store.commit("setLoading", true);
      let link =
        typeof this.userId == "undefined"
          ? this.$store.getters.baseURL +
            "power/transactions/admin/all?page=" +
            this.currentPage
          : this.$store.getters.baseURL +
            "power/transactions/admin/" +
            this.userId +"/all?page=" +
            this.currentPage;
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result.data.data[0].disco);
          if (result.status) {
            this.transactions = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    searchTransactions() {
      this.searching = true;
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.searching = false;
        this.getTransactions();
        return;
      }
      let link =
        typeof this.userId == "undefined"
          ? this.$store.getters.baseURL +
            "power/transactions/admin/search/" +
            this.keyword + "?page="+this.currentPage
          : this.$store.getters.baseURL +
            "power/transactions/admin/" +
            this.userId +
            "/search/" +
            this.keyword + "?page="+this.currentPage;
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.transactions = result.data.data;
              this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    }
  }
};
</script>

<style scoped>
</style>